.login-modal .ant-modal-content {
  border-radius: 38px;
  padding: 0;
  overflow: hidden;
}
.login-modal-container {
  height: 720px;
  display: flex;
  flex-direction: row;
}
.close {
  width: 24px;
  height: 24px;
}
.login-modal-container .login-modal-left {
  width: 374px;
  background: #71b07b;
  border-radius: 38px 0px 0px 38px;
  height: 100%;
  position: relative;
}
.login-modal-container .login-modal-right {
  width: 726px;
  background: #fff;
  height: 100%;
  position: relative;
}
.login-modal-container .login-modal-right .form-container {
  padding-top: 69px;
  flex: 1;
  margin-left: 176px;
  margin-right: 110px;
}

.login-modal-container .login-logo {
  padding-left: 45px;
  padding-top: 27px;
}
.login-modal-container .login-bg {
  position: absolute;
  left: -10px;
  top: 96px;
  width: 502px;
}

.login-modal-right .ant-tabs-nav-list {
  width: 100%;
}
.login-modal-right .ant-tabs-nav-list > div {
  flex: 1;
}
.login-modal-right .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  height: 8px;
  border-radius: 8px;
}
.login-modal-right .ant-tabs-tab-btn {
  font-size: 24px;
  font-weight: 400;
  padding-left: 5px;
}
.login-modal-right .ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 4px solid #727272;
  margin: 0 3px;
}
.login-modal-right form {
  padding-top: 40px;
}
.form-item {
  border: 2px solid #176a62;
  border-radius: 100px;
  overflow: hidden;
  padding-left: 31px;
  margin-bottom: 24px;
  position: relative;
}
.form-item .form-item-label {
  color: #000;
  font-size: 18px;
  font-weight: 700;
  margin-right: 5px;
  min-width: 4em;
}
.form-item input {
  padding: 16px 0;
  flex: 1;
  padding-left: 28px;
  border: none;
  color: rgba(0, 0, 0, 0.6);
  font-size: 18px;
  font-weight: 350;
}

.form-item .code {
  width: 108px;
  height: 60px;
  background-color: #54a75c;
  position: absolute;
  right: 0;
  border-radius: 100px;
  user-select: none;
}
.form-item .code:hover {
  cursor: pointer;
}
.form-item .code:active {
  opacity: 0.8;
}
.form-item .code span {
  color: #fff;
  font-size: 14px;
  font-weight: 350;
}
.login-modal-right .ant-btn.ant-btn-lg {
  height: 60px;
}
.login-modal-right .ant-btn.ant-btn-lg {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}
.login-flotter {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 46px;
  background: rgba(113, 176, 123, 0.2);
  padding-left: 90px;
}
.acc-login {
  color: #176a63;
  font-size: 16px;
  font-weight: 700;
  height: 46px;
  line-height: 46px;
  padding-right: 110px;
}
