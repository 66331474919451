.head-con {
  /* width: 1920px; */
  height: 80px;
  background: lightgray -3.84px -468px / 100.831% 986.349% no-repeat;
  display: flex;
  align-content: center;
  padding-left: 107px;
  padding-right: 107px;
  position: fixed;
  z-index: 999;
  width: 100%;
  top: 0;
}
.input-box {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 2rem;
  position: relative;
}
.keywordPopover{
  pointer-events: unset!important;
}
.keywordPopover .lis-box {
  width: 100%;
  background-color: #fff;
}
.keywordPopover .lis-box .list-cont {
  flex-direction: row;
  display: flex;
}
.keywordPopover .lis-box .list-cont > div {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 5px 10px;
  border-radius: 10px;
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 350;
  cursor: pointer;
  line-height: normal;
  margin-right: 10px;
}
.keywordPopover .empty{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.keywordPopover .empty span{
  display: block;
}
.head-con .menu-list {
  display: flex;
  align-items: center;
  column-gap: 34px;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  > div {
    cursor: pointer;
  }
}
.input-pos {
  position: relative;
}
.input-box img {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  left: 20px;
  margin-top: -12px;
}
.keyword {
  background-color: #fff;
  width: 20rem;
  height: 48px;
  border-radius: 20px;
  border: 2px solid #000;
  background: rgba(255, 255, 255, 0.6);

  font-size: 20px;
  font-weight: 350;
  padding-left: 58px;
}
.keyword:focus {
  border: 2px solid #000;
}

.user {
  width: 60px;
  height: 60px;
  border-radius: 60px;
}
.user-info {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  align-items: end;
  margin-right: 20px;
}
.user-info .role {
  font-weight: 400;
}

/*  */
.keywordPopover .ant-popover-arrow{
  display: none;
}
.keywordPopover .ant-popover-content{
  width: 512px;
}