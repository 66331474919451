.teacher-container {
  background: #eeeee6;
  min-height: 100vh;
}
.teacher-container .my-info {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  height: 15vh;
}

.teacher-container .teacher-container .my-vector {
  width: 80px;
  height: 80px;
  line-height: 80px;
  border-radius: 80px;
  background-color: #fff;
  position: relative;
  margin-right: 40px;
  margin-left: 40px;
}
.teacher-container .teacher-container .my-vector span {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  display: inline-block;
  line-height: 25px;
  text-align: center;
  background-color: #ff5e54;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  top: -5px;
  right: -5px;
}
.teacher-container .my-user-pic {
  width: 3rem;
  border: 1px solid #fff;
  border-radius: 60px;
}
.teacher-container .left-box {
  width: 480px;
  border-top-right-radius: 90px;
  border-bottom-right-radius: 90px;
  padding-left: 42px;
  padding-right: 19px;
  height: 12vh;
}
.teacher-container .left-box .logo {
  width: auto;
  height: 6rem;
  cursor: pointer;
}
.teacher-container .right-box {
  flex: 1;
  border-radius: 90px 0px 0px 90px;
  background-size: cover;
  padding-left: 40px;
  margin-left: 30px;
  height: 12vh;
}

.teacher-container .teacher-container .my-vector img {
  width: 40px;
  height: 40px;
}

.teacher-container .right-box .username {
  margin-left: 60px;
}
.teacher-container .right-box .username .nikename {
  color: #fff;
  font-size: 2rem;
  font-weight: 700;
}

.teacher-container .right-box .username div:nth-child(2) {
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
}
.teacher-container .right-box .username .career {
  display: inline-block;
  height: 20px;
  border-radius: 60px;
  background: #fff;
  padding: 0 15px;
  color: #176a62;
  font-size: 14px;
  font-weight: 400;
  margin-left: 30px;
}

.teacher-container .my-info .right-box .user-integral {
  margin-left: 54px;
}

.teacher-container .my-info .right-box .user-integral-num {
  color: #fff;
  font-size: 2rem;
  font-weight: 700;
}
.teacher-container .my-info .right-box .user-integral-txt {
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
}
.teacher-container .my-info .right-box .my-setting {
  width: 140px;
  height: 58px;
  border-radius: 60px;
  border: 2px solid #fff;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  margin-right: 294px;
}
.teacher-container .my-info .right-box .my-setting span {
  margin-left: 5px;
}

.teacher-container .my-nav-list,
.teacher-container .my-manage-class-list {
  width: 15rem;
  margin-left: 87px;
  border-radius: 24px;
  background: #fff;
  overflow: visible;
  flex: none;
}
.teacher-container .child-view,
.teacher-container .my-nav-list,
.teacher-container .my-manage-class-list,
.teacher-container .three-route {
  box-sizing: border-box;
}
.teacher-container .my-nav-list,
.teacher-container .my-manage-class-list {
  padding-top: 16px;
}
.teacher-container .my-nav-list li,
.teacher-container .my-manage-class-list li {
  padding-left: 13px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 2;
  height: 4rem;
}
.teacher-container .my-nav-list li .bag,
.teacher-container .my-manage-class-list li .bag {
  width: 22px;
  height: 22px;
  line-height: 22px;
  display: inline-block;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border-radius: 22px;
  position: absolute;
  right: 5px;
  z-index: 1;
  background-color: #ff5e54;
  text-align: center;
}
/* .my-nav-list li:hover {
  background-color: rgba(113,176,123, 0.2);
} */
.teacher-container .mi-my-cour-ul {
  overflow: visible;
}
.teacher-container .my-manage-class-list li.active {
  background: rgba(110, 227, 234, 0.2);
}
.teacher-container .my-nav-list li.active .mine-nav-txt {
  color: #00648e;
  font-size: 1.3rem;
  font-weight: bold;
}
.teacher-container .my-nav-list li .mine-nav-icon {
  width: 24px;
  height: 24px;
  margin-right: 13px;
  overflow: hidden;
}
.teacher-container .my-nav-list li img:nth-child(1) {
  width: 24px;
  height: 24px;
}
.teacher-container .my-nav-list li img:nth-child(3) {
  width: 16rem;
  height: 4rem;
  position: absolute;
  left: 0;
  z-index: -1;
  display: none;
}
.teacher-container .my-nav-list li.active img:nth-child(3) {
  display: block;
}
.teacher-container .my-nav-list li.active img:nth-child(1) {
  /* filter: brightness(100); */
  margin-left: -30px;
  margin-right: 43px;
  filter: drop-shadow(30px 0 0 #00648e);
}

.teacher-container .my-manage-class-list {
  margin: 0;
}

.teacher-container .child-view {
  margin-left: 22px;
  margin-right: 87px;
  border-radius: 24px;
  box-shadow: 0px 0.22222px 3.50231px 0px rgba(0, 0, 0, 0.01),
    0px 0.97778px 7.25185px 0px rgba(0, 0, 0, 0.02),
    0px 2.4px 14.4625px 0px rgba(0, 0, 0, 0.03),
    0px 4.62222px 28.34815px 0px rgba(0, 0, 0, 0.03),
    0px 7.77778px 52.12268px 0px rgba(0, 0, 0, 0.04),
    0px 12px 89px 0px rgba(0, 0, 0, 0.05);
  overflow: auto;
  background: #f3faf9;
  margin-bottom: 0;
}
.teacher-container .te-sub-nav {
  position: relative;
  height: 100%;
  margin-left: 22px;
}
.te-create-class {
  width: 75%;
  height: 45px;
  border-radius: 80px;
  border: 1px solid #00648e;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
.te-create-class:active {
  opacity: 0.7;
}
.te-create-class span {
  color: #00648e;
  font-size: 1rem;
  font-weight: 400;
}
