.title {
  color: #000;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
}
.infolist {
  margin-top: 1rem;
}
.infolist li {
  border-bottom: 1px solid rgba(115, 113, 183, 0.2);
  margin: 0 2rem;
}
.infolist li > label {
  color: #000;
  font-size: 1.2rem;
  font-weight: 700;
}
.infolist li > input {
  padding: 1rem 0.5rem;
  flex: 1;
  text-align: right;
  color: #000;
  font-size: 1.2rem;
  font-weight: 400;
}
.btnGulp {
  margin-top: 3rem;
}
.btnGulp > div {
  padding: 2px 3rem;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 400;
  border-radius: 12px;
  cursor: pointer;
}
.btnGulp > div:active {
  opacity: 0.7;
}
.btnGulp .confirm {
  background: #00648e;
}
.btnGulp .cancel {
  background: #e2f9fb;
  color: #00648e;
  font-weight: 400;
  margin-left: 1rem;
}
