.logo-box,
.tab-box {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logo {
  width: 62px;
  height: 62px;
}
.logo-box span {
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 20px;
}
