.cont {
  display: flex;
  justify-content: center;
  background: #eeeee6;
  padding: 20px;
  padding-top: 100px;
}
.source-box {
  padding: 15px;
  border-radius: 24px;
  background: #fff;
  height: calc(100vh - 120px);
  flex: 1;
  box-shadow: 0px 0.222px 3.502px 0px rgba(0, 0, 0, 0.01),
    0px 0.978px 7.252px 0px rgba(0, 0, 0, 0.02),
    0px 2.4px 14.462px 0px rgba(0, 0, 0, 0.03),
    0px 4.622px 28.348px 0px rgba(0, 0, 0, 0.03),
    0px 7.778px 52.123px 0px rgba(0, 0, 0, 0.04),
    0px 12px 89px 0px rgba(0, 0, 0, 0.05);
}
.source-put {
  width: 244.241px;
  height: 41.87px;
  flex-shrink: 0;
  border-radius: 60px;
  border: 1px solid #00648e;
  background: #f3faf9;
  display: flex;
  align-items: center;
  padding: 1px;
}
.source-put > img {
  margin-left: 0.5rem;
}
.put {
  background: #f3faf9;
  height: 100%;
  flex: 1;
  border-radius: 0 60px 60px 0;
}
.tab-head {
  padding: 0 15px 15px 0px;
}

.source-table {
}
/* .source-box .ant-pagination {
  position: absolute;
  right: 76px;
  top: -80px;
}
.source-box .ant-pagination-item-link {
  width: 26px;
  height: 26px;
  background: #f3faf9 !important;
} */

.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-row-hover,
.ant-table-row-hover > td {
  background-color: #e2f9fb !important;
}

.ant-pagination-item {
  width: 78px;
  height: 26px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #00648e;
  background: #f3faf9;
}
.ant-table-thead > tr > th {
  background-color: #f2f9f8 !important;
}
