.State{
    padding: 20px;
    display: flex;
    flex-direction: column;
}
.State-box{
    flex: 1;
    border-radius: 24px;
    background: #FFF;
    display: flex;
    flex-direction: column;

    /* 投影-网页 */
    box-shadow: 0px 2.4px 7.2px 0px rgba(0, 0, 0, 0.03), 0px 4.62px 4px 0px rgba(0, 0, 0, 0.03), 0px 0.78px 4px 0px rgba(0, 0, 0, 0.05);
}
.state-table{
    height: 600px;

}
.state-put{
    width: 234.568px;
    height: 40.212px;
    border-radius: 60px;
    border: 1px solid  #00648E;
    background: #F3FAF9;
    display: flex;
    align-items: center;
    margin: 20px 0 10px 40px;
}