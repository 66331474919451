.pay-content {
  width: 100%;
  height: 28.0625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.6rem;
}
.pay-item {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}
.pay-active {
  border: 1px solid #f01414;
  position: relative;
  overflow: hidden;
}
.pay-active::after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 20px solid #f01414;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  bottom: -10px;
  right: -15px;
  transform: rotate(130deg);
}
.wx-pay {
  width: 8.73531rem;
  height: 3.75rem;
  background: #f4f4f5;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.88rem;
  cursor: pointer;
}
.pay-infor {
  margin-top: 2.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.6rem;
}
.pay-infor > div:first-child {
  color: #666767;
  font-family: Source Han Sans CN;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
}
.pay-infor > div:nth-child(3) {
  color: #666767;
  font-family: Source Han Sans CN;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
}
.reload {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  margin-top: 2rem;
}
/* 定义旋转动画 */
@keyframes rotate {
  0% { transform: rotate(0deg); } /* 初始状态为不旋转 */
  100% { transform: rotate(360deg); } /* 结束状态为完全旋转一周（360度）*/
}
 
/* 应用旋转动画到元素上 */
.pay-infor .element {
  animation-name: rotate; /* 指定要应用的动画名称 */
  animation-duration: 1s; /* 设置动画持续时间 */
  animation-iteration-count: 3; /* 无限次重复动画 */
  animation-timing-function: linear; /* 线性变化速度函数 */
}

