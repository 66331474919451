.game-box {
  display: flex;
  flex-direction: column;
  padding: 30px 66px;
  position: relative;
  height: 100%;
}
.game-item {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 16px;
  overflow-y: scroll;
}
.game-item::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.game-list {
  cursor: pointer;
  height: 139px;
  border-radius: 20px;
  background: #fff;
  display: flex;
  align-items: center;
  margin-top: 1rem;
}
.glass {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
}
.list-content {
  display: flex;
  flex-direction: column;
  row-gap: 22px;
  margin-left: 30px;
  flex: 1;
}
.list-content > div:first-child {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.list-content > div:last-child {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.list-right {
  margin-right: 34px;

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  justify-content: center;
}
.list-right > div:first-child {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  width: 116.167px;
  height: 40.212px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 60px;
  background: #71b07b;
}
.list-right > div:last-child {
  color: var(--, #888b9b);
  font-family: Source Han Sans CN;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
