.stu-container {
  padding-right: 22px;
  padding-left: 22px;
  overflow-y: auto;
}
.stu-container .goback span {
  height: 26px;
  line-height: 26px;
  color: #666767;
  font-size: 16px;
  font-weight: 400;
}
.stu-container .student-user {
  height: 60px;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 0 10px;
  margin-top: 15px;
}

.stu-container .student-user img {
  width: 44px;
  height: 44px;
  border-radius: 44px;
}

.stu-container .student-user span {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  margin-left: 15px;
}
.course-page-containe .mine-my-cour-ul > li {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 3px 2px #eee;
  margin-bottom: 15px;
  overflow: hidden;
  padding: 20px 30px;
}
.stu-container .stu-navs {
  margin-top: 20px;
}
.stu-container .stu-navs .navs-ul li {
  font-size: 16px;
}
.stu-container .study-img {
  width: 83px;
  height: 83px;
  border-radius: 8px;
}
.stu-container .main-info .main-info-detail > span {
  min-width: 20%;
}
.stu-container .main-info-detail svg {
  margin-left: 5px;
}
.stu-container .progressive {
  display: inline-block;
  transform: rotate(180deg);
}
.stu-container .competition-score {
  width: 83px;
  height: 83px;
  line-height: 83px;
  text-align: center;
  border-radius: 83px;
  background-color: #e2f9fb;
  font-size: 20px;
  font-weight: 700;
}
.score-level-1 {
  color: #f1af13;
}

.score-level-2 {
  color: #458b86;
}
.score-level-3 {
  color: #7a92ce;
}

.stu-container .competition-score-text {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  margin-top: 11px;
}




