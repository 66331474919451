.addCoursePage {
  display: flex;
  flex-direction: column;
}
.addCourseCon {
  margin-top: 1rem;
  overflow-y: auto;
}
.addCoursePage .addCourseList {
  margin-top: 0;
  height: 100%;
}
.addCoursePage .searchCon {
  padding: 1rem 1rem 0 1rem;
}
.courseBg {
  width: 86px;
  height: 55px;
  border-radius: 4px;
  object-fit: cover;
  background: lightgray 50% / cover no-repeat;
}
.title {
  color: #000;
  font-size: 20px;
  font-weight: 600;
}
.inputCon {
  margin-left: 2rem;
}
.courseList {
}
.courseList li {
  display: flex;
  margin-top: 1.2rem;
  align-items: center;
  padding: 0 1rem;
}
.courseList li:hover {
  cursor: pointer;
  background: rgba(110, 227, 234, 0.2);
}
.courseInfo {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.courseInfo span:nth-child(1) {
  color: #000;
  font-size: 1rem;
  font-weight: 600;
}
.courseInfo span:nth-child(2) {
  color: #666767;
  font-size: 14px;
  font-weight: 500;
}

.price {
  color: #ff8914;
  font-size: 1rem;
  font-weight: 500;
  flex: 1;
}
.join,
.joinSucc {
  color: #00648e;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 60px;
  background: #e2f9fb;
  padding: 3px 9px;
}
.joinSucc {
  color: #aaa;
  opacity: 0.8;
}
.empty {
  padding-top: 5rem;
  text-align: center;
}
.emptyImg img {
  width: 10rem;
  height: 10rem;
  border-radius: 10rem;
  background: rgb(220, 226, 203);
}
.emptyTxt {
  color: #000;
  font-size: 1.2rem;
  font-weight: 400;
  margin-top: 0.3rem;
}
.emptyTxt .tip {
  color: #71b07b;
}

.emptyBox {
  margin-top: 5rem;
}
.addCoursePage .studentTitle {
  color: #000;
  font-size: 1.2rem;
  font-weight: 600;
  padding-left: 0;
  margin: 0 1rem;
}

.toBuy {
  margin: 0.5rem 1rem;
  padding-top: 0.5rem;
  border-top: 1px solid #a3aeaf;
}
.toBuyBtn {
  border-radius: 60px;
  background: #00648e;
  height: 2rem;
  line-height: 2rem;
  color: #fff;
  font-size: 0.8rem;
  font-weight: 400;
  padding: 0 1rem;
  cursor: pointer;
  user-select: none;
}
.toBuyBtn:active,
.cacelCourseBtn:active {
  opacity: 0.7;
  user-select: none;
}

.cacelCourseBtn {
  color: #00648e;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 60px;
  background: #e2f9fb;
  padding: 3px 16px;
  margin-left: 10px;
  display: inline-block;
}
