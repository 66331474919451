.page {
  background: #eeeee6;
  display: flex;
  justify-content: center;
}
.oj-main {
  height: 100vh;
  padding-top: 104px;
}
.oj-main .child-view {
  overflow-y: auto;
}
