.my-start-practice {
  color: #71b07b;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  margin-top: 10px;
}
.mine-item-bg {
  width: 112px;
  height: 112px;
}
.mine-item-bg img {
  width: 102px;
  height: 103px;
}
