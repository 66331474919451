
.match-container .summary {
  width: 100%;
  padding-left: 54px;
  padding-right: 39px;
  padding-top: 22px;
}
.match-container .progress-box + .progress-box{
  margin-left: 44px;
}
.match-container .progress-box {
  border-radius: 24px;
  background: #fff;
  height: 156px;
  padding: 0 26px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}
.match-container .progress-info span {
  display: block;
}
.match-container .progress-info .txt {
  color: #666767;
  font-size: 16px;
  font-weight: 400;
}
.match-container .progress-info .val {
  font-size: 32px;
  font-weight: 700;
}
.match-container .progress-info .val-1 {
  color: #7a92ce;
}
.match-container .progress-info .val-2 {
  color: #458b86;
}
.match-container .progress-info .val-3 {
  color: #f1af13;
}
.match-container .progress-info .val-4 {
  color: #ce4c6c;
}
.del-game{
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding:0 21px;
  align-items: center;
  justify-content: center;
  color: #888B9B;
  margin-left: 20px;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  width: 62px;
  height: 157px;
  flex-shrink: 0;
  border-radius: 24px;
  background: #E2F9FB;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
}
.num{
  padding:7px 10px;
  background-color: #F1AF13;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}