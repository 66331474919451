.navs-ul {
  border-bottom: 2px solid #a3aeaf;
  overflow: visible;
  position: relative;
}
.navs-ul li {
  margin-right: 45px;
  position: relative;
  color: #000;
  font-size: 1.5rem;
  cursor: pointer;
  padding-bottom: 8px;
}
.navs-ul li.nav-ative {
  border-radius: 8px;
  color: #00648e;
  font-weight: 700;
}
.navs-ul li.nav-ative::after {
  content: " ";
  position: absolute;
  width: 100%;
  height: 6px;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  background: linear-gradient(270deg, #5379b1 13.83%, #70e4eb 82.98%);
  border-radius: 18px;
}

.navs-ul li.nav-right {
  position: absolute;
  right: 0;
  margin-right: 0;
}
