.tea-container {
  height: 100%;
  padding: 22px 1rem;
  background: #f3faf9;
}
.three-route .te-sub-nav {
  height: 95%;
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
}
.tea-container .system-main {
  margin-left: 22px;
}
.tea-container .system-sub {
  height: 100%;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}
.tea-container .student-search {
  padding: 20px 2rem;
  border-bottom: 1px solid #a3aeaf;
}
.tea-container .search-input {
  width: 12rem;
  border-radius: 60px;
  background: #eeeee6;
  padding-left: 15px;
}
.tea-container .search-input input {
  width: 100%;
  height: 40px;
  border-radius: 60px;
  background: #eeeee6;
  padding-left: 0.3rem;
}
.tea-container .student-ul {
  padding-top: 30px;
  flex: 1;
}
.tea-container .student-ul li {
  padding-left: 44px;
  padding-right: 50px;
  margin-bottom: 17px;
}
.tea-container .student-ul li img {
  width: 4rem;
  height: 4rem;
}

.tea-container .student-ul li div {
  flex: 1;
  padding-left: 17px;
  color: #00648e;
  font-size: 1rem;
  font-weight: 400;
}
.te-btn {
  color: #00648e;
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
  border-radius: 60px;
  background: #e2f9fb;
  display: inline-block;
  padding: 3px 16px;
  margin-left: 10px;
}
.te-btn:active {
  opacity: 0.7;
}
.te-btn-gray {
  color: #666767;
  font-size: 1rem;
  font-weight: 400;
}
.tea-container .student-ul li:hover {
  background: rgba(110, 227, 234, 0.2);
}
/*  */
.tea-container .apply-box {
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 0.8rem 0.7rem;
  height: 48%;
  overflow: auto;
}
.tea-container .te-course-list {
  margin-top: 4%;
}
.tea-container .apply-title .m-title {
  color: #000;
  font-size: 20px;
  font-weight: 600;
}

.tea-container .apply-title .m-notice-num {
  width: 22px;
  height: 22px;
  border-radius: 22px;
  display: inline-block;
  line-height: 22px;
  text-align: center;
  background-color: #ff5e54;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin-left: 17px;
}
.tea-container .apply-ul {
  padding-top: 22px;
}
.tea-container .apply-ul li {
  margin-bottom: 15px;
}
.tea-container .apply-ul .sq-pic {
  width: 55px;
  height: 55px;
}

.tea-container .apply-ul .sq-name {
  width: 4em;
  color: #000;
  font-size: 1rem;
  font-weight: 600;
  padding-left: 17px;
}
.tea-container .apply-ul .sq-txt {
  flex: 1;
  padding-left: 22px;
  color: #00648e;
  font-size: 20px;
  font-weight: 400;
}
.add-course-btn {
  height: 40px;
  padding: 0 1rem;
  line-height: 40px;
  border-radius: 60px;
  background: #e2f9fb;
  margin-left: 1rem;
  font-size: 0.9rem;
}
.add-course-btn svg {
  margin-right: 5px;
}
.tea-container .apply-ul .kc-pic {
  width: 85px;
  height: 85px;
  border-radius: 4px;
  object-fit: cover;
}

.tea-container .people-num {
  color: #00648e;
  font-size: 1.5rem;
  font-weight: 700;
}

.teacher-container .my-manage-class-list {
  height: 100%;
  padding-bottom: 100px;
}
