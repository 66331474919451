.mi-create-modal .title {
  color: #000;
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
}
.mi-create-modal .del-title {
  color: #000;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid rgba(115, 113, 183, 0.2);
}
.mi-create-modal .create-class-input {
  width: 100%;
  padding-bottom: 36px;
  padding-top: 18px;
  border-bottom: 1px solid rgba(115, 113, 183, 0.2);
  position: relative;
}
.mi-create-modal .create-class-input input {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  border: 1px solid #00648e;
  background: #f1f8f2;
  color: #000;
  font-size: 0.9rem;
  font-weight: 400;
  padding-left: 20px;
  box-sizing: border-box;
}
.mi-create-modal .create-class-input-box {
  position: relative;
  width: 100%;
  height: 44px;
}
.mi-create-modal .create-class-input span {
  width: 80px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  top: 0;
  right: -1px;
  border-radius: 6px;
  border: 1px solid #00648e;
  background: #00648e;
  text-align: center;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
  box-sizing: border-box;
}
.mi-create-modal .create-class-input span:active {
  opacity: 0.7;
}
.mi-create-modal .btn-gulp {
  text-align: center;
}
.mi-create-modal .btn-gulp .te-btn {
  width: 124.4px;
  height: 34px;
  border-radius: 12px;
  background: #00648e;
  color: #fff;
  text-align: center;
}
.mi-create-modal .btn-gulp .cancel {
  background: #e2f9fb;
  color: #00648e;
}
