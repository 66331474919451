.detail-container {
  min-width: 1000px;
}
.de-body {
  width: 100%;
  background: #eeeee6;
  backdrop-filter: blur(7px);
  background-size: cover;
  padding-bottom: 70px;
  padding-top: 100px;
}
.de-footer {
  width: 100%;
  background-size: cover;
  min-height: 300px;
  border: 1px solid transparent;
  padding-bottom: 100px;
}
.breadcrumbs {
  padding-left: 169px;
  padding-top: 22px;
  color: #000;
  font-size: 16px;
  font-weight: 400;
}

.breadcrumbs svg {
  margin: 0 13px;
}
.course-info {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 65px;
  display: flex;
  justify-content: space-between;
}
.course-info .course-info-left {
  flex: 1;
}
.course-info .course-info-right {
  width: 716px;
}
.course-info .title {
  color: #000;
  font-size: 36px;
  font-weight: 700;
  padding: 0 14px;
}
.course-info .introduce {
  margin-top: 16px;
  width: 100%;
  padding: 0 14px;
  color: #000;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
}
.duration {
  color: #000;
  font-size: 18px;
  font-weight: 700;
  padding-left: 14px;
  margin-top: 34px;
}
.duration .line {
  width: 4px;
  height: 16px;
  background: #71b07b;
  margin: 0 12px;
}

.ta-2 {
  color: #71b07b;
}
.course-info-left .price {
  color: #f9780b;
  font-size: 36px;
  font-weight: 900;
  margin-top: 34px;
}

button {
  border: none;
  cursor: pointer;
}
.btn-gulp {
  margin-top: 36px;
  padding-left: 14px;
}
.btn {
  width: 70px;
  height: 32px;
  background-color: transparent;
  color: #000;
  font-size: 16px;
  font-weight: 700;
  margin-right: 40px;
}
.btn-active {
  border-radius: 60px;
  background: #71b07b;
  color: #fff;
}
.course-info-left .hr {
  width: 100%;
  height: 2px;
  margin-top: 17px;
  background: rgba(48, 48, 48, 0.4);
}
.course-info-left .tip {
  padding-top: 28px;
}
.course-info-left .edit {
  width: 40px;
  height: 40px;
}
.course-info-left .tip span {
  color: #000;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 2px;
}
.course-info-left .harvest {
  padding: 0 50px 40px 50px;
  border-radius: 12px;
  background: #f2f6e4;
  margin-top: 15px;
}
.course-info-left .harvest .round {
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 12px;
  background-color: #71b07b;
}
.course-info-left .harvest .harvest-50 {
  width: 50%;
  display: inline-block;
  margin-top: 42px;
}
.course-info-left .harvest .harvest-item1 {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  margin-left: 10px;
  letter-spacing: 1.6px;
}
.course-info-right .video-box {
  border-radius: 14px;
  width: 100%;
  height: 413px;
  position: relative;
  overflow: hidden;
}
.course-info-right .video-box video {
  height: 100%;
  width: 100%;
  background: #232e22;
}
.course-info-right .video-box .video-info {
  position: absolute;
  top: 135px;
  left: 286px;
  cursor: pointer;
}
.course-info-right .video-box .video-txt {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  transform: translateY(-100%);
}
.course-info-right .video-box .video-title {
  padding-left: 88px;
  padding-right: 88px;
  background: #71b07b;
  margin-top: -6px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.course-info-right .video-box .video-title svg {
  padding: 20px;
  cursor: pointer;
}
.course-info-right .video-box .video-title span {
  flex: 1;
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-weight: 900;
}
.course-info-right .operate {
  width: 240px;
  height: 64px;
  line-height: 64px;
  text-align: center;
  border-radius: 300px;
  background: linear-gradient(93deg, #f67a02 8.86%, #f1af14 94.15%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 50px;
}
.course-info-right .operate:active {
  opacity: 0.8;
}
.course-info-right .cart {
  background: #f2f6e4;
  color: #f57d03;
  margin-left: 35px;
}
.nav-list {
  height: 162px;
  border-radius: 26px;
  background: #f2f6e4;
  margin: 0 auto;
  margin-top: 18px;
  box-shadow: 0px 4px 4px 0px #7ba497;
}
.nav-list > div {
  color: #000;
  font-size: 32px;
  font-weight: 500;
  margin: 0 51.5px;
  cursor: pointer;
  user-select: none;
}

.nav-list .active {
  color: #71b07b;
  position: relative;
}
.nav-list .active:after {
  content: " ";
  width: 28px;
  height: 4px;
  display: inline-block;
  border-radius: 90px;
  background: #71b07b;
  position: absolute;
  bottom: -4px;
  left: 50%;
  margin-left: -14px;
}
.nav-list,
.de-footer .course-introduction {
  max-width: 1280px;
}
.de-footer {
  /* padding: 0 2rem; */
}
.de-footer .course-introduction {
  border-radius: 26px;
  background: #f2f6e4;
  box-shadow: 0px 4px 4px 0px #7ba497;
  padding-left: 63px;
  padding-right: 77px;
  margin: 0 auto;
  margin-top: 36px;
}
.de-footer .course-introduction .title {
  color: #000;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 2.4px;
  padding-top: 46px;
}
.de-footer .course-introduction .teacher-info {
  margin-top: 32px;
}
.de-footer .course-introduction .teacher-info img {
  width: 76px;
  height: 76px;
  border-radius: 76px;
  object-fit: cover;
}
.teacher-info .teacher-c {
  padding-left: 16px;
}
.teacher-info .teacher-c span:first-child {
  color: #000;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 2px;
}
.teacher-info .teacher-c span:last-child {
  color: #71b07b;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 1.6px;
  margin-top: 4px;
}
.course-introduction .sub {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
  line-height: 25px;
}
.course-details {
  color: #000;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 2.4px;
  margin-top: 40px;
}
.course-details-sub {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  margin-top: 20px;
  padding-bottom: 85px;
}
.directory-box {
  padding-top: 58px;
  padding-bottom: 50px;
}
.course-introduction .directory {
  width: 513px;
  height: 60px;
  border-radius: 16px;
  background: #71b07b;
  margin-bottom: 24px;
}
.course-introduction .directory img {
  width: 60px;
  height: 60px;
}
.course-introduction .directory span {
  color: #fff;
  font-size: 24px;
  font-weight: 350;
}
.directory-list li {
  margin-bottom: 30px;
}

.directory-list li .directory-item {
  line-height: 70px;
  border-radius: 16px;
  background: #71b07b;
  padding-left: 18px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}
.directory-list li .directory-item .name {
  margin-left: 30px;
}
.directory-list li .directory-item svg {
  margin-left: 30px;
  transition: transform 0.5s;
}
.directory-list li .vedio-play-info {
  padding-top: 26px;
  padding-left: 22px;
  color: #666767;
  font-size: 24px;
  font-weight: 350;
  display: none;
}
.directory-list li .vedio-play-info svg {
  width: 30px;
  height: 30px;
}
.directory-list li .vedio-play-info .no-item {
  cursor: pointer;
}
.directory-list li .vedio-play-info .no-item:not(:first-child) {
  margin-top: 14px;
}
.directory-list li .vedio-play-info .no-name {
  display: inline-block;
  margin-left: 50px;
}
.directory-list li .vedio-play-info .try-txt {
  color: #71b07b;
  margin-left: 50px;
}

.directory-list li .vedio-play-info .no-minute {
  color: #71b07b;
  font-size: 20px;
  font-weight: 350;
  margin-left: 35px;
}
.directory-list .active .directory-item svg {
  transform: rotate(180deg);
}
.directory-list .active .vedio-play-info {
  display: block;
}

/*  */
.comment-introduction {
  padding: 0 140px;
  width: 1280px;
  margin: 0 auto;
  margin-top: 15px;
  border-radius: 26px;
  background: #f2f6e4;
  box-shadow: 0px 4px 4px 0px #7ba497;
}
.comment-introduction .de-trophy {
  padding-top: 48px;
  padding-left: 500px;
}
.comment-introduction .de-trophy img {
  width: 60px;
  height: 60px;
}
.comment-introduction .de-btn-gulp {
  padding-top: 40px;
  padding-bottom: 20px;
}
.comment-introduction .de-btn-gulp button {
  padding: 11px 18px;
  border-radius: 16px;
  margin-bottom: 18px;
  background: rgba(113, 176, 123, 0.4);
  color: #fff;
  font-size: 16px;
  font-weight: 900;
  margin: 0 10px;
}
.comment-introduction .de-btn-gulp .active {
  background: #71b07b;
}
.comment-introduction .comment-list {
  border-top: 2px solid #d9d9d9;
}
.comment-introduction .comment-list > li {
  padding-top: 24px;
}
.comment-introduction .userpic {
  width: 60px;
  height: 60px;
  border-radius: 60px;
}
.comment-introduction .userinfo {
  margin-left: 24px;
  border-bottom: 2px solid #d9d9d9;
  padding-bottom: 24px;
  flex: 1;
}

.comment-introduction .userinfo .username {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1.6px;
}
.comment-introduction .userinfo .report {
  color: #71b07b;
  font-size: 14px;
  font-weight: 350;
  letter-spacing: 1.4px;
  cursor: pointer;
}

.comment-introduction .userinfo .usertime {
  color: #71b07b;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1.4px;
  margin-right: 24px;
}
.comment-introduction .userinfo .comment {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1.6px;
  margin-top: 18px;
}
.no-item .play-btn:hover {
  opacity: 0.7;
}
