.mine-container {
  background: #eeeee6;
  min-height: 100vh;
}
.mine-container .my-info {
  overflow: hidden;
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  height: 15vh;
}
.mine-container .my-info .my-left-bg {
  width: 343px;
  height: 100%;
  border-top-right-radius: 90px;
  border-bottom-right-radius: 90px;
  padding: 0 1rem;
}

.my-left-bg .back-home {
  width: auto;
  height: 6rem;
  cursor: pointer;
}
.mine-container .my-vector span {
  width: 25px;
  height: 25px;
  /*border-radius: 25px;*/
  /*display: inline-block;*/
  /*line-height: 25px;*/
  /*text-align: center;*/
  /*background-color: #ff5e54;*/
  /*color: #fff;*/
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  top: -5px;
  right: -5px;
}
.mine-container .my-info .my-user-pic {
  width: 3rem;
  height: 3rem;
  border: 1px solid #fff;
  border-radius: 60px;
}

.mine-container .my-info .right-box {
  flex: 1;
  height: 100%;
  border-radius: 90px 0px 0px 90px;
  background-size: cover;
  padding-left: 40px;
  margin-left: 30px;
}

.mine-container .my-vector img {
  width: 40px;
  height: 40px;
}

.mine-container .my-info .right-box .username {
}
.mine-container .my-info .right-box .user-box span:nth-child(1) {
  color: #fff;
  font-size: 1.8rem;
  font-weight: 700;
}

.mine-container .my-info .right-box .user-box .user-integral-s2 {
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
}
.mine-container .my-info .right-box .user-integral {
  margin-left: 54px;
}
.mine-container .my-info .right-box .my-setting {
  width: 140px;
  height: 58px;
  border-radius: 60px;
  border: 2px solid #fff;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
}
.mine-container .my-info .right-box .my-setting span {
  margin-left: 5px;
}

.mine-container .my-nav-list {
  width: 176px;
  margin-left: 10%;
  border-radius: 24px;
  background: #fff;
  overflow: visible;
  flex: none;
}
.child-view,
.my-nav-list {
  height: 95%;
}
.my-nav-list li {
  padding-left: 13px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 2;
  height: 60px;
}
/* .my-nav-list li:hover {
  background-color: rgba(113,176,123, 0.2);
} */

.my-nav-list li.active span {
  color: #00648e;
  font-size: 1.2rem;
  font-weight: bold;
}
.my-nav-list li .mine-nav-icon {
  width: 24px;
  height: 24px;
  margin-right: 13px;
  overflow: hidden;
}
.my-nav-list li img:nth-child(1) {
  width: 24px;
  height: 24px;
}
.my-nav-list li img:nth-child(3) {
  width: 204px;
  height: 60px;
  position: absolute;
  left: -8px;
  z-index: -1;
  display: none;
}
.my-nav-list li.active img:nth-child(3) {
  display: block;
}
.my-nav-list li.active img:nth-child(1) {
  /* filter: brightness(100); */
  margin-left: -30px;
  margin-right: 43px;
  filter: drop-shadow(30px 0 0 #00648e);
}

.mine-main {
  width: 100%;
  height: 85vh;
}
.child-view {
  margin-left: 40px;
  margin-right: 10%;
  border-radius: 24px;
  box-shadow: 0px 0.22222px 3.50231px 0px rgba(0, 0, 0, 0.01),
    0px 0.97778px 7.25185px 0px rgba(0, 0, 0, 0.02),
    0px 2.4px 14.4625px 0px rgba(0, 0, 0, 0.03),
    0px 4.62222px 28.34815px 0px rgba(0, 0, 0, 0.03),
    0px 7.77778px 52.12268px 0px rgba(0, 0, 0, 0.04),
    0px 12px 89px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 73px;
  overflow: hidden;
  background: #f3faf9;
}
.current-user-role {
  display: inline-block;
  height: 20px;
  border-radius: 60px;
  background: #fff;
  padding: 0 15px;
  color: #176a62;
  font-size: 14px;
  font-weight: 400;
  margin-left: 30px;
}
