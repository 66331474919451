.classInfo {
  padding: 1rem;
}
.teInfo {
  border-radius: 30px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 0.5rem 0.4rem 0.5rem;
}
.teName {
  color: #000;
  font-size: 0.8rem;
  font-weight: bold;
  margin-left: 0.3rem;
}
.teSubName {
  color: #666767;
  font-size: 0.7rem;
  margin-left: 3rem;
}
.classCourseList {
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
}
.classTit {
  color: #000;
  font-size: 1rem;
  font-weight: 500;
  padding: 1rem 1.3rem;
  padding-bottom: 0;
}
.courseUl {
}
.courseUl li {
  padding: 1rem 1.3rem;
}
.courseUl li:hover {
  background: rgba(110, 227, 234, 0.2);
}
.courseUl li img {
  width: 6rem;
  object-fit: cover;
  height: 4rem;
  border-radius: 4px;
}
.courseName {
  flex: 1;
  padding-left: 1rem;
  color: #000;
  font-size: 1rem;
  font-weight: bold;
}
.courseBtn {
  border-radius: 60px;
  background: #e2f9fb;
  color: #00648e;
  font-size: 1rem;
  font-weight: 400;
  padding: 0.5rem 1rem;
  cursor: pointer;
	user-select: none;
}
