.user-box {
  padding: 20px 40px;
}
.user-content {
  border-radius: 24px;
  background: #fff;
  flex: 1;
  /* 投影-网页 */
  box-shadow: 0px 2.4px 7.2px 0px rgba(0, 0, 0, 0.03),
    0px 4.62px 4px 0px rgba(0, 0, 0, 0.03),
    0px 0.78px 4px 0px rgba(0, 0, 0, 0.05);
}
.user-title {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}
