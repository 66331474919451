.course-carousel {
  margin: 0 auto;
  margin-top: 100px;
  overflow: hidden;
}
.course-carousel {
  max-width: 1834px;
  width: 100%;
  padding: 0 30px;
}
.course-carousel img {
  object-fit: cover;
  width: 100%;
  border-radius: 24px;
  height: 40rem;
}
.course-banner-slide {
  text-align: center;
}

.swiper-container {
  height: 40rem;
}
.swiper-container .swiper-wrapper .swiper-slide {
  /* border-radius: 10px; */
}
.swiper-container .swiper-wrapper .swiper-slide img {
  width: 100%;
  height: 100%;
  border-radius: 0;
}
.course-button-prev,
.course-button-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  cursor: pointer;
}
.course-button-prev {
  left: 34px;
}
.course-button-next {
  right: 34px;
}
.course-button-prev img,
.course-button-next img {
  width: 86px;
  height: 86px;
}

.classification .classification-line-1 {
  color: #000;
  display: flex;
  align-items: flex-end;
}
.classification .classification-line-1 img {
  width: 70px;
  height: 70px;
}
.classification .classification-line-1 :nth-child(2) {
  font-size: 32px;
  font-weight: 700;
}

.classification .classification-line-1 :nth-child(3) {
  font-size: 16px;
  font-weight: 350;
  padding-bottom: 2px;
  margin-left: 20px;
}
.classification .classification-line-2 {
  color: #000;
  display: flex;
  align-items: center;
  padding-top: 36px;
}
.classification .classification-line-3 {
  align-items: flex-start;
}
.classification .classification-line-3 .lab {
  padding-top: 3px;
}
.classification .classification-line-2 > span {
  display: inline-block;
  width: 70px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.classification .classification-line-2 li {
  color: #000;
  font-size: 20px;
  font-weight: 400;
  padding: 5px 13px;
  cursor: pointer;
  margin-right: 30px;
}
.classification .classification-line-2 li.active {
  border-radius: 8px;
  background: #dce2cb;
  color: #176a61;
  font-size: 20px;
  font-weight: 700;
}
.co-course-container {
  max-width: 1834px;
  width: 100%;
  padding: 30px;
}
.co-class-container {
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 83px;
  background-color: #fff;
  border-radius: 24px;
}
.co-course-ul-container {
  padding: 50px 86px;
  background-color: #fff;
  border-radius: 24px;
  margin-top: 38px;
}
.co-class-container .classification {
  /* min-height: 347px; */
  background-size: cover;
}
.co-class-container .class-list {
  flex-wrap: wrap;
}
.co-course-container .course-search {
  padding-top: 36px;
}
.co-course-container .course-search-ul li {
  padding: 4px 18px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}
.co-course-container .course-search-ul li.active {
  border-radius: 60px;
  background: #176a63;
  color: #fff;
}

.co-pagination ul {
  justify-content: flex-end;
}

.co-course-ul-container {
  width: 100%;
  border-radius: 18px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  display: inline-block;
}
.co-course-container::-webkit-scrollbar {
  display: none;
}

.co-course-ul-container .co-course-li-info {
  width: 100%;
  height: 200px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 18px 18px 0px 0px;
  position: relative;
  padding-top: 24px;
  padding-left: 25px;
  color: #fff;
  font-weight: 900;
  background-size: cover;
  overflow: hidden;
  z-index: 1;
  cursor: pointer;
}
.co-course-ul-container .co-course-li-info::after {
  content: " ";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  display: inline-block;
  z-index: -1;
}
.co-course-ul-container .co-course-li-info span:nth-child(1) {
  font-size: 24px;
}

.co-course-ul-container .co-course-li-info span:nth-child(2) {
  font-size: 14px;
}
.co-course-ul-container .co-course-li-info span:nth-child(3) {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 24px;
  line-height: 24px;
  border-radius: 0px 14px 0px 0px;
  border: 1px solid #fff;
  background: #ff8914;
  padding-left: 13px;
  padding-right: 20px;
  font-size: 16px;
}
.co-course-ul-container .co-course-item-fo {
  padding-top: 13px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 25px;
}

.co-course-ul-container .co-course-price {
  color: #ff8914;
  font-size: 22px;
  font-weight: 900;
}
