.page {
  background-color: rgb(238, 238, 230);
}
.in-container {
  background: rgb(238, 238, 230);
  padding-bottom: 100px;
  overflow: hidden;
}
.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 106px;
  padding-right: 106px;
  /* position: absolute;
  top: 0;
  left: 0;
  z-index: 20; */
  width: 100%;
  background: #dce2cb;
}
.head .ant-tabs-nav::before {
  border-bottom: none;
}
.logo-box,
.tab-box {
  display: flex;
  align-items: center;
}

.tab-box .tab-list-item {
  color: #176a62;
  font-size: 20px;
  font-weight: 700;
  position: relative;
  margin-left: 34px;
}
.tab-box .tab-list-active::after {
  content: " ";
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 4px;
  border-radius: 8px;
  background: #176a62;
}
.tab-box .userinfo {
  margin-left: 8rem;
  position: relative;
}
.tab-box .userinfo .my-name {
  color: #111;
  font-size: 16px;
  font-weight: 700;
}
.dropdown {
  width: 228px;
  border-radius: 12px;
  background: #fff;
  z-index: -1;
}

.dropdown .integ {
  margin-left: 22px;
}
.dropdown .username {
  color: #176a62;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1.6px;
  text-align: center;
}
.tab-box .userinfo:hover .my-name {
  visibility: hidden;
}
.dropdown .lab {
  color: #9499a0;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 1px;
}
.dropdown .val {
  color: #18191c;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 1px;
}

.dropdown .my-center {
  border-radius: 5px;
  background-size: cover;
  margin: 0 20px;
  padding: 5px 14px;
  margin-top: 6px;
}
.dropdown .my-center .my-integral-x {
  color: #176a62;
  font-size: 12px;
  font-weight: 700;
}
.dropdown .my-center .my-integral-t {
  color: #176a62;
  font-size: 10px;
  font-weight: 400;
}
.dropdown .my-center .my-center-btn {
  border-radius: 4px;
  background: #176a62;
  color: #fff;
  font-size: 10px;
  font-weight: 400;
  padding: 4px 6px;
}

.dropdown .dropdown-item {
  margin: 0 20px;
  padding: 0.8rem;
}
.dropdown .dropdown-item:hover {
  opacity: 0.8;
}
.dropdown-bb {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.dropdown .dropdown-item .course-t {
  color: #666;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1.2px;
  margin-left: 10px;
}
.dropdown .course-out {
  padding-bottom: 0;
}
.tab-box .userinfo .my-name {
  margin-right: 10px;
}

.dropdown .my-center .my-center-btn:active {
  opacity: 0.8;
}

.tab-box .userinfo img {
  width: 70px;
  height: 70px;
  border-radius: 39px;
  border: 1px solid #fff;
}
.tab-box button {
  width: 163.714px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 39px;
  background: #000;
  border: none;
  margin-left: 105px;
}
.tab-box button:hover {
  cursor: pointer;
  opacity: 0.9;
}
.tab-box button:active {
  opacity: 0.8;
}
.logo {
  width: 62px;
  height: 62px;
}
.logo-box span {
  color: #176a62;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 20px;
}

.login-btn {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.banner {
  width: 100%;
  height: 1200px;
  object-fit: cover;
}
.banner-carousel {
  margin: 0 106px;
  overflow: hidden;
}
/* .banner-carousel .ant-carousel .slick-slide {
  text-align: center;
  height: 1200px;
  line-height: 160px;
  background: #dce2cb;
  overflow: hidden;
} */

.notice-carousel .ant-carousel .slick-slide {
  text-align: center;
  width: 799px;
  height: 400px;
  overflow: hidden;
}

.notice-left,
.notice-right {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.notice-carousel .notice-left {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-470px, -50%);
  z-index: 1;
}
.notice-carousel .notice-right {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(470px, -50%);
  z-index: 1;
}
.notice-left img,
.notice-right img {
  width: 100%;
  height: 100%;
}

.in-container .notice-carousel {
  width: 800px;
  height: 400px;
  position: relative;
  margin: 0 auto;
}

.in-container .notice-carousel .notice-swiper-container {
  overflow: hidden;
}
.in-container .notice-carousel .notice-img {
  width: 176px;
  height: 176px;
  display: inline-block;
}
.in-container .notice-carousel .swiper-slide {
  width: 60%;
  height: 300px;
  transition: all 0.3s ease-in-out;
  text-align: center;
  text-align: center;
}
.in-container .notice-carousel .swiper-slide-prev,
.in-container .notice-carousel .swiper-slide-next {
  transform: scale(0.9);
}

.in-container .notice-carousel .notice-txt-box span {
  color: #000;
  text-align: center;
  padding: 0 10px;
  display: block;

  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}
.in-container .notice-carousel .notice-txt-box .notice-txt-1 {
  font-size: 18px;
  letter-spacing: 6.4px;
  margin-top: 10px;
}
.in-container .notice-carousel .notice-txt-box .notice-txt-2 {
  font-size: 16px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 4.8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notice {
  margin-top: 105px;
}
.notice .notice-title span {
  display: block;
}
.notice .notice-title span:first-child {
  color: #000;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 6.4px;
}

.notice .notice-title span:last-child {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.6px;
  text-align: center;
  margin-top: 22px;
}

/* -------------- */
.active-box {
  margin-top: 520px;
  position: relative;
}
.active-box .title {
  color: #fff;
  margin-left: 25px;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 4.8px;
}
.active-box .girl {
  position: absolute;
  top: -405px;
  left: 47%;
  width: 606px;
  height: 687px;
  flex-shrink: 0;
  z-index: 1;
}
.active-left,
.active-right {
  height: 803px;
  border-radius: 38px;
  background: #71b07b;
}
.active-left {
  width: 1184px;
  position: relative;
  padding-left: 59px;
  padding-right: 94px;
  padding-top: 74px;
  display: flex;
  flex-direction: column;
}
.active-right {
  width: 490px;
  margin-left: 46px;
  position: relative;
}
.index-ul {
  overflow: hidden;
  height: 100%;
  flex: 1;
}
.item {
  display: flex;
  align-items: center;
  border-radius: 28px;
  background: #eeeee6;
  position: relative;
  padding: 30px 0;
  margin-bottom: 10px;
}
.item .competition-box {
  position: relative;
}
.item .name {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  position: relative;
  z-index: 2;
}
.competition {
  padding-left: 22px;
  width: 100%;
}
.competition > div {
  margin-top: 10px;
}
.trophy {
  width: 102px;
  height: 103px;
}
.item .competition .line {
  width: 60px;
  height: 10px;
  border-radius: 40px;
  background: #f9c365;
  display: inline-block;
  position: absolute;
  bottom: -2px;
  left: 0;
  z-index: 1;
}
.detail .label {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.6px;
  margin-right: 20px;
}
.detail .value {
  color: #eb7531;
  font-weight: 700;
}
.item .detail > div {
  flex: 1;
}
.label-img {
  width: 78px;
  height: 79px;
  position: absolute;
  top: -15px;
  right: 48px;
}
.detail .color-green {
  color: #406147;
}

.more {
  color: #fff;
  font-size: 36px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3.6px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 76px;
  padding-right: 48px;
}
.add {
  width: 30px;
  height: 30px;
  margin-left: 5px;
}
.search {
  margin-top: 113px;
}
.search .search-input {
  position: relative;
  width: 80%;
  margin: 0 auto;
}
.search input {
  width: 100%;
  height: 81px;
  border-radius: 20px;
  background: #eeeee6;
  padding-left: 60px;
  border: none;

  color: #616161;
  font-size: 32px;
  font-weight: 350;
}
.search-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 17px;
  width: 30px;
  height: 30px;
}
.search-pepo {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.teacher {
  display: flex;
  margin-top: 56px;
  position: relative;
}
.teacher-img {
  width: 400px;
  height: 400px;
  margin: 0 5%;
}
.introduce {
  width: 319px;
  margin-right: -29px;
}
.introduce .name {
  color: #040002;
  font-size: 32px;
  font-weight: 700;
}
.introduce .course {
  margin-top: 16px;
  position: relative;
}
.introduce .course .det-main {
  color: #000;
  font-size: 24px;
  font-weight: 700;
  position: relative;
  z-index: 2;
}

.introduce .course .det-name {
  color: #406147;
  font-size: 24px;
  font-weight: 700;
}
.introduce .course .line {
  width: 80px;
  height: 10px;
  border-radius: 40px;
  background: #dce2cb;
  display: inline-block;
  position: absolute;
  bottom: -2px;
  left: 0;
  z-index: 1;
}
.introduce .sub {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  margin-top: 12px;
  line-height: 36px;
}

.mention {
  width: 96px;
  height: 302px;
  border-radius: 20px 60px;
  background: #dce2cb;
  position: relative;
}
.mention span {
  display: inline-block;
  width: 1em;
  color: #fff;
  font-size: 32px;
  font-weight: 700;
}
.mention .huojian-img {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: -30px;
}

/*  */
.teacher-list {
  width: 1184px;
  height: 176px;
  margin: 0 auto;
  border-radius: 38px;
  background: #dce2cb;
  padding: 0 50px;
  margin-top: 57px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 550px;
}
.teacher-item {
  display: flex;
  width: 134px;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.teacher-item img {
  width: 80px;
  height: 80px;
  object-fit: contain;
}
.teacher-item span {
  margin-top: 15px;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
}
.teacher-list .teacher-active {
  background-color: #efaf14;
  height: 196px;
  border-radius: 20px;
  margin-top: -10px;
}
.ellipse {
  width: 2178px;
  height: 976px;
  border-radius: 50%;
  background: #dce2cb;
  position: absolute;
  bottom: -750px;
  left: 50%;
  transform: translateX(-50%);
}

.icon-list .ant-float-btn-group {
  width: auto;
}
.icon-list .ant-float-btn-circle {
  width: 100px;
  height: 57px;
}
.icon-list .ant-float-btn-icon {
  width: 100px;
  height: 57px;
}
.icon-list .ant-float-btn-body:hover {
  background-color: transparent;
}
.icon-list .ant-float-btn-body {
  border-radius: 20px;
}
.icon-list .anticon {
  width: 100%;
  height: 100%;
  justify-content: center;
}
.markdown-typed-wrap {
  padding: 20px;
}
.markdown-typed-wrap p {
  margin-top: 10px;
}
