.course-page-containe,
.mine-page-containe {
  padding: 24px 60px;
  height: 100%;
  padding-right: 0;
  overflow-y: auto;
}

.course-page-containe .mine-my-cour-ul {
  margin-top: 26px;
  padding-right: 20px;
}
.course-page-containe .mine-my-cour-ul > li {
  padding: 20px 30px;
  background-color: #fff;
  margin-bottom: 15px;
  border-radius: 20px;
  box-shadow: 0px 3px 2px #eee;
  overflow: hidden;
}
.course-page-containe .mi-nav-box {
  padding: 0 30px;
}
.course-page-containe .mi-my-cour-pic {
  width: 20%;
  height: 8rem;
  object-fit: cover;
  border-radius: 8px;
}
.course-page-containe .main-info {
  padding-left: 32px;
  width: 60%;
}
.course-page-containe .join-study {
  width: 20%;
  display: flex;
  justify-content: flex-end;
}
.course-page-containe .main-info .main-info-title {
  color: #000;
  font-size: 1.4rem;
  font-weight: 700;
}

.course-page-containe .main-info .main-info-subtitle {
  color: #666767;
  font-size: 14px;
  font-weight: 500;
  margin-top: 4px;
}

.course-page-containe .main-info .main-info-detail {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}

.course-page-containe .main-info .main-info-detail > span {
  min-width: 25%;
  display: inline-block;
  color: #000;
  font-size: 1rem;
  font-weight: 500;
  padding-top: 20px;
}
.course-page-containe .main-info .main-info-detail .color-green {
  color: #71b07b;
  margin-left: 10px;
}

.course-page-containe .main-info .main-info-detail .color-warning {
  color: #ff8914;
}
