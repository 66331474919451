.pl-bread {
  padding-top: 126px;
  padding-bottom: 46px;
}
.pl-body,
.pl-footer {
  padding-left: 169px;
  padding-right: 103px;
}
.play-info {
  display: flex;
  align-items: center;
  height: 617px;
}
.play-info-left {
  flex: 1;
  margin-right: 25px;
  position: relative;
  height: 100%;
}
.play-info-left .video-js {
  padding-top: 0 !important;
}
.play-info-left video {
  height: 100%;
  width: 100%;
  background-color: #303030;
}
.play-info-left svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.play-info-right {
  width: 30%;
  height: 100%;
  border-radius: 16px 0px 0px 0px;
  background: #f2f6e4;
}
.play-info-right .directory-list .name {
  font-size: 24px;
}
.play-info-right .directory-list .no-play {
  color: #71b07b;
}
.play-info-right .directory-list {
  overflow-y: auto;
  height: 100%;
}

.pl-body .pl-title {
  color: #000;
  font-size: 36px;
  font-weight: 700;
  padding-top: 20px;
}
.pl-sub-title {
  color: #000;
  font-size: 20px;
  font-weight: 400;
  padding-top: 16px;
  max-width: 1098px;
}
.pl-footer {
  padding-top: 23px;
  border: none;
}
.pl-footer .pl-nav-list {
}
.comment-introduction .pl-commo,
.comment-introduction .pl-search {
  padding-top: 35px;
  font-size: 24px;
  font-weight: 500;
}
.comment-introduction .pl-commo :nth-child(1) {
  color: #000;
}
.comment-introduction .pl-commo :nth-child(2) {
  color: #71b07b;
  margin-left: 5px;
}
.comment-introduction .pl-search {
  margin-left: 34px;
  position: relative;
}
.comment-introduction .pl-comment-list {
  border-bottom: 2px solid #d9d9d9;
}
.comment-introduction .pl-comment-list li:last-child .userinfo {
  border: none;
}
.comment-introduction .pl-search :nth-child(2) {
  margin: 0 15px;
  display: inline-block;
  width: 1px;
  height: 14px;
  background: #727272;
}
.comment-introduction .user-comment {
  padding: 40px 0;
}
.comment-introduction .pl-list-floter-tip {
  color: #71b07b;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-align: center;
  padding-top: 48px;
}
.comment-introduction .pl-pagination {
  padding-top: 63px;
  padding-bottom: 155px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.comment-introduction .pl-pagination ul {
  flex: none;
}
.comment-introduction .pl-pagination .ant-pagination .ant-pagination-item {
  width: 80px;
  height: 45px;
  line-height: 45px;
  border-radius: 8px;
  border: 1px solid #71b07b;
}
.comment-introduction
  .pl-pagination
  .ant-pagination
  .ant-pagination-item-active {
  background-color: #71b07b !important;
}
.comment-introduction
  .pl-pagination
  .ant-pagination
  .ant-pagination-item-active
  a {
  color: #fff;
}
.comment-introduction .ant-pagination .ant-pagination-prev,
.comment-introduction .ant-pagination .ant-pagination-next {
  width: 80px;
  height: 45px;
  line-height: 45px;
  border-radius: 8px;
  border: 1px solid #71b07b;
}

.play-ani {
  animation: myfirst 0.5s infinite;
  animation-direction: alternate;
}
@keyframes myfirst {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}
