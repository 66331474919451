.content {
  padding: 40px 20px;
  height: 100%;
  display: flex;
  column-gap: 1rem;
}
.content-left {
  display: flex;
  flex-direction: column;
  padding: 1.1rem 0.8rem;
  width: 244px;
  height: 100%;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 0.222px 3.502px 0px rgba(0, 0, 0, 0.01),
    0px 0.978px 7.252px 0px rgba(0, 0, 0, 0.02),
    0px 2.4px 14.462px 0px rgba(0, 0, 0, 0.03),
    0px 4.622px 28.348px 0px rgba(0, 0, 0, 0.03),
    0px 7.778px 52.123px 0px rgba(0, 0, 0, 0.04),
    0px 12px 89px 0px rgba(0, 0, 0, 0.05);
}
.left-txt {
  color: #000;
  font-family: Source Han Sans CN;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.ti-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.8rem;
  height: 80%;
  overflow-y: scroll;
}
.ti-list::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.list-active {
  cursor: pointer;
  width: 9.9715rem;
  align-items: center;
  height: 1.97456rem;
  border-radius: 0.5rem;
  border: 1px solid #e2f9fb;
  background: #e2f9fb;
  margin-bottom: 10px;
}
.ti-list-item {
  cursor: pointer;
  width: 9.9715rem;
  height: 1.97456rem;
  border-radius: 0.5rem;
  border: 1px solid #666767;
  background: #fff;
  margin-bottom: 10px;
}
.content-auto {
  display: flex;
  flex-direction: column;
  width: 244px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 0.222px 3.502px 0px rgba(0, 0, 0, 0.01),
    0px 0.978px 7.252px 0px rgba(0, 0, 0, 0.02),
    0px 2.4px 14.462px 0px rgba(0, 0, 0, 0.03),
    0px 4.622px 28.348px 0px rgba(0, 0, 0, 0.03),
    0px 7.778px 52.123px 0px rgba(0, 0, 0, 0.04),
    0px 12px 89px 0px rgba(0, 0, 0, 0.05);
}
.c-listT {
  display: flex;
  align-items: center;
  width: 100%;
  height: 2.46819rem;
  background: #eff6f5;
  color: #000;
  font-family: Source Han Sans CN;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  column-gap: 2.3rem;
  padding: 0 15px;
}
.course-list {
  height: 80%;
  overflow-y: scroll;
}
.course-list::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.c-list {
  display: flex;
  align-items: center;
  border: none;
  border-radius: 0;
  width: 100%;
  height: 2.46819rem;
}
.c-listActive {
  background: #e2f9fb;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 0;
  width: 100%;
  height: 2.46819rem;
  color: #00648e;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.c-list > div:last-child {
  margin-left: 3.6rem;
}
.c-listActive > div:last-child {
  margin-left: 3.6rem;
}

.auto-txt {
  margin: 1.1rem 0.6rem;
  color: #000;
  font-family: Source Han Sans CN;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.content-right {
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-shrink: 0;
  border-radius: 0.75rem;
  background: #fff;
  box-shadow: 0px 0.222px 3.502px 0px rgba(0, 0, 0, 0.01),
    0px 0.978px 7.252px 0px rgba(0, 0, 0, 0.02),
    0px 2.4px 14.462px 0px rgba(0, 0, 0, 0.03),
    0px 4.622px 28.348px 0px rgba(0, 0, 0, 0.03),
    0px 7.778px 52.123px 0px rgba(0, 0, 0, 0.04),
    0px 12px 89px 0px rgba(0, 0, 0, 0.05);
}
.top-ipt {
  margin: 1rem 1.6rem;
  height: 1.8rem;
  border: 1px solid #00648e;
  background: #f0f7f6;
  position: absolute;
  z-index: 99;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.top-ipt img {
  width: 20px;
  height: 20px;
  margin: 0 10px;
}
.top-ipt > input {
  background: transparent;
  border-radius: 60px;
  height: 100%;
  flex: 1;
  padding: 0 10px;
}

.ant-table-thead {
  color: #000;
  font-weight: 500;
}
.ant-pagination-item {
  width: 4.875rem;
  height: 1.625rem;
  flex-shrink: 0;
  border-radius: 0.25rem;
  border: 1px solid #00648e !important;
  background: #f3faf9;
  color: #00648e;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
