.faq-box {
  padding: 20px 70px;
}
.faq-content {
  border-radius: 24px;
  flex: 1;
  /* height: 650px; */
  background: #fff;
  padding: 35px 88px;
  /* 投影-网页 */
  box-shadow: 0px 2.4px 7.2px 0px rgba(0, 0, 0, 0.03),
    0px 4.62px 4px 0px rgba(0, 0, 0, 0.03),
    0px 0.78px 4px 0px rgba(0, 0, 0, 0.05);
}
.t1 {
  color: #000;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.t2 {
  margin-top: 20px;
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.t3 {
  margin-top: 10px;
  color: #666767;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.tm {
  margin: 30px 0;
}
td {
  padding: 10px;
}
.txt-tab {
  margin-top: 20px;
  /*border: 1px solid black;*/
}
.txt-tab > tr > td:first-child {
  border: 1px solid black;
}
.txt-tab > tr > td:last-child {
  padding: 10px 20px 10px 10px;
  border: 1px solid black;
}
