.teach-page-containe {
  position: relative;
  border-radius: 24px;
  background: #f3faf9;
  box-shadow: 0px 0.222px 3.502px 0px rgba(0, 0, 0, 0.01),
    0px 0.978px 7.252px 0px rgba(0, 0, 0, 0.02),
    0px 2.4px 14.462px 0px rgba(0, 0, 0, 0.03),
    0px 4.622px 28.348px 0px rgba(0, 0, 0, 0.03),
    0px 7.778px 52.123px 0px rgba(0, 0, 0, 0.04),
    0px 12px 89px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 2.7rem;
  height: 95%;
  padding: 24px 24px 24px 60px;
}
.mine-info-edit-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;
}
.mine-info-edit-btn span {
  cursor: pointer;
  border-radius: 80px;
  background: linear-gradient(270deg, #5379b1 -5.79%, #6ee3ea 111.57%);
  padding: 0.8rem 1.5rem;
  color: #fff;
  font-size: 1rem;
  user-select: none;
}
.mine-info-edit-btn span:active{
  opacity: 0.7;
}
