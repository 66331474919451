.mi-my-cour-ul {
  text-align: right;
  cursor: pointer;
  user-select: none;
}
.match-container {
  display: flex;
  flex-direction: column;
}
.mine-page-containe {
  position: relative;
}
.select-time {
  border-radius: 12px;
  background: #ececec;
  width: 388.713px;
  height: 44.68px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.infor-list {
  margin-top: 32px;
  display: flex;
  row-gap: 16px;
  height: 300px;
  flex-direction: column;
}
.infor-list > li {
  height: 60px;
  border-radius: 20px;
  background: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.infor-list .tit span:nth-child(1) {
  display: inline-block;
  min-width: 6rem;
}
.infor-list .tit span:nth-child(2) {
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
}

.tit {
  margin-left: 38px;
  color: #000;
  font-size: 1.2rem;
  font-weight: bold;
  width: 100%;
}
.tj-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;
}
.tj-btn span {
  cursor: pointer;
  border-radius: 80px;
  background: linear-gradient(270deg, #5379b1 -5.79%, #6ee3ea 111.57%);
  padding: 0.8rem 1.5rem;
  color: #fff;
  font-size: 1rem;
  user-select: none;
}
.but-box {
  display: flex;
  margin: 24px 42px;
  column-gap: 22px;
}
.box-one {
  flex: 1;
  .time-box {
    width: 430.042px;
    height: 139.624px;
    flex-shrink: 0;
    border-radius: 24px;
    background: #fff;
    padding: 17px 24px;
    display: flex;
    flex-direction: column;
    row-gap: 17px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    .title {
      color: #000;

      font-family: Source Han Sans CN;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .course-list {
    width: 430.042px;
    height: 405.468px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    padding: 18px 26px;
    margin-top: 22px;
    row-gap: 20px;
    border-radius: 24px;
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    .txt {
      color: #000;
      font-family: Source Han Sans CN;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .course-item {
      align-items: center;
      display: flex;
      justify-content: space-between;

      .course-left {
        width: 331.747px;
        height: 49.148px;
        border-radius: 12px;
        background: #e2f9fb;
        display: flex;
        align-items: center;
        justify-content: center;
        olor: #00648e;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .course-right {
        margin-left: 11px;
        width: 44.68px;
        height: 49.148px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        border-radius: 12px;
        background: #ececec;
      }
    }
  }
}
.box-two {
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow-y: scroll;

  border-radius: 24px;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 17px 26px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}
.title {
  display: flex;
  justify-content: space-between;
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.timu {
  display: flex;
  flex-direction: column;
}
.titx {
  display: flex;
  align-items: center;
  margin-top: 18px;
}
.titx-left {
  color: #00648e;
  align-items: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  height: 49.148px;
  flex: 1;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 12px;
  background: #e2f9fb;
}
.titx-right {
  width: 44.68px;
  height: 49.148px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  background: #e2f9fb;
  margin-left: 11px;
  justify-content: center;
}
.list {
  margin-top: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.list-one {
  color: #00648e;

  font-family: Source Han Sans CN;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.list-two {
  flex: 1;
  margin-left: 36px;
  color: #7a92ce;
  font-family: Source Han Sans CN;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
list-three {
  display: flex;
  flex-direction: row;
}
.box-three {
  flex: 1;
  height: 568px;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 18px 24px;
}
.paimimg {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.minepaiming {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 13px;
}
.user-list {
  margin-top: 17px;
}
.user-infor {
  display: flex;
  align-items: center;
}
.user {
  margin-left: 13px;
  align-items: center;
  flex: 1;
  display: flex;
}
.name {
  display: flex;
  flex-direction: column;
}
.name-zs {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.name-bj {
  color: #666767;

  font-family: Source Han Sans CN;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.fen {
  width: 44.68px;
  height: 44.68px;
  border-radius: 60px;
  background: #e2f9fb;
  color: #00648e;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Source Han Sans CN;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}
