html,
body,
#root {
  margin: 0;
  padding: 0;
  font-family: Source Han Sans CN, Roboto, Helvetica, sans-serif;

  font-size: 1vw;
}
#root {
  position: relative;
}
* {
  margin: 0;
  padding: 0;
}
ul,
li {
  list-style: none;
}
.page-container {
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0 auto;
  background: #eeeee6;
}

div {
  box-sizing: border-box;
}

.App-logo {
  height: 100vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.app-ff {
  font-family: Source Han Sans CN !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.udr {
  display: flex;
  flex-direction: row;
}
.udc {
  display: flex;
  flex-direction: column;
}
.uac {
  align-items: center;
}
.uae {
  align-items: flex-end;
}
.ujc {
  justify-content: center;
}
.ujb {
  justify-content: space-between;
}
.uje {
  justify-content: flex-end;
}
.uf1 {
  flex: 1;
}
.curp {
  cursor: pointer;
  user-select: none;
}
.curp:active {
  opacity: 0.7;
}
input {
  border: none;
}
input:focus {
  border: none;
  outline: none;
}
button.mma-btn {
  background-color: #71b07b;
}
:where(.css-dev-only-do-not-override-1j4x3ou).ant-btn-primary:disabled,
:where(.css-dev-only-do-not-override-1j4x3ou).ant-btn-primary.ant-btn-disabled {
  background: #d5e1d1;
  color: #fff;
}
.line-hide {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.line-hide-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

/* 设置滚动条的样式 */

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: silver;
  border-radius: 3px;
}

.information {
  text-align: center;
  font-size: 14px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 5px 0;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  position: relative;
}
