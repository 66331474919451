.studentTitle {
  color: #000;
  font-size: 1rem;
  font-weight: 600;
  margin: 1rem;
  margin-bottom: 0rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #a3aeaf;
}
.orderMain {
  padding: 0 5%;
}
.leftBox {
  width: 30%;
  height: 95%;
  overflow-y: auto;
  border-radius: 24px;
  background: #fff;
  padding: 0 1rem;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}
.orderMain .leftBox .studentList li{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.peoNum {
  color: #00648e;
  font-size: 1.5rem;
  font-weight: 700;
}
.peoUnit {
  color: #666767;
  font-size: 1rem;
  font-weight: 350;
}
.orderInfo {
  flex: 1;
  padding: 0 1rem;
  height: 95%;
  margin-left: 2rem;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 0.222px 3.502px 0px rgba(0, 0, 0, 0.01),
    0px 0.978px 7.252px 0px rgba(0, 0, 0, 0.02),
    0px 2.4px 14.462px 0px rgba(0, 0, 0, 0.03),
    0px 4.622px 28.348px 0px rgba(0, 0, 0, 0.03),
    0px 7.778px 52.123px 0px rgba(0, 0, 0, 0.04),
    0px 12px 89px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
}
.goback {
  border-bottom: 1px solid #a3aeaf;
  padding: 1rem 0;
}
.clouseInfo {
  padding: 0.5rem 1rem;
  margin: 0 -1rem;
  border-radius: 8px;
  background: #f4f4f5;
  margin: 0.5rem 0;
}
.line1 {
  height: 1px;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #a3aeaf;
}
.courseBg {
  border-radius: 8px;
  object-fit: cover;
  width: 8rem;
  height: 6rem;
}
.courseTxt {
  padding-left: 1rem;
  justify-content: space-evenly;
  flex: 1;
}
.courseTxt .titName {
  color: #000;
  font-size: 1rem;
  font-weight: 500;
}
.courseTxt .subName {
  color: #666767;
  font-size: 0.8rem;
  font-weight: 500;
  margin-top: 0.1rem;
}
.ordernumber {
  padding-right: 2rem;
  color: #00648e;
  font-size: 2rem;
  font-weight: 500;
}
.table {
  flex: 1;
  overflow-y: auto;
  border-bottom: 1px solid #a3aeaf;
}
.table li {
  display: flex;
  padding: 0 0.5rem;
}
.tHead {
  border-radius: 8px;
  background: #f4f4f5;
  display: flex;
}
.table li span {
  flex: 1;
  color: #666767;
  font-size: 0.8rem;
  font-weight: 500;
  padding: 0.5rem 0;
  text-align: left;
}
.table .tHead span {
  color: #000;
  font-size: 1rem;
}
.toastInfo {
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.toast1 {
  color: #000;
  font-size: 1rem;
  font-weight: 500;
  flex: 1;
  text-align: right;
}
.toast1 span {
  color: #00648e;
  font-size: 1rem;
  font-weight: 500;
}
.toast2 span {
  color: #f01414;
  font-size: 1.1rem;
  font-weight: 900;
}
.settlement {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 400;
  border-radius: 12px;
  background: #f01414;
  padding: 0.3rem 1rem;
  margin-left: 1rem;
  cursor: pointer;
}
.settlement:active{
  opacity: 0.7;
  user-select: none;
}
