.schCon {
  border-radius: 24px;
  background: #fff;
  /* 投影-网页 */
  box-shadow: 0px 2.4px 7.2px 0px rgba(0, 0, 0, 0.03),
    0px 4.62px 4px 0px rgba(0, 0, 0, 0.03),
    0px 0.78px 4px 0px rgba(0, 0, 0, 0.05);
  margin: 1rem 2rem;
  background-color: #fff;
  padding: 0 0.5rem;
	flex: 1;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}
.addCourseList {
  margin-top: 0;
  height: 100%;
}
.searchCon {
  padding: 1rem 1rem 0 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #a3aeaf;
  position: relative;
}
.title {
  color: #000;
  font-size: 1rem;
  font-weight: 600;
}

.inputCon {
  border-radius: 60px;
  background: #eeeee6;
  padding-left: 0.3rem;
}
.inputCon input {
  background-color: transparent;
  padding: 0.5rem 0.3rem;
}

.addBtn {
  border-radius: 60px;
  background: #00648e;
  padding: 0.2rem 1rem;
  cursor: pointer;
  position: absolute;
  right: 0;
}
.addBtn:active {
  opacity: 0.8;
}
.addBtn span {
  font-size: 0.8rem;
  color: #fff;
  margin-left: 0.2rem;
}
.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.listCon{
	height: 100%;
	display: flex;
	flex-direction: column;
}
.listCon ul {
  flex: 1;
	overflow-y: auto;
}
.listCon ul li{
	padding: 0.5rem 1rem;
}
.listCon ul li  img {
  width: 3rem;
  height: 3rem;
  border-radius: 90px;
}
.username{
	margin-left: 0.3rem;
}
.username span:nth-child(1) {
  color: #000;
  font-size: 1rem;
  font-weight: 400;
}

.username span:nth-child(2) {
  font-size: 0.7rem;
  color: #00648e;
  font-size: 14px;
  font-weight: 400;
}
