.courseBg {
  width: 86px;
  height: 55px;
  border-radius: 4px;
  object-fit: cover;
  background: lightgray 50% / cover no-repeat;
}
.courseInfo {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.courseInfo span:nth-child(1) {
  color: #000;
  font-size: 1rem;
  font-weight: 600;
}
.courseInfo span:nth-child(2) {
  color: #666767;
  font-size: 14px;
  font-weight: 500;
}
.price {
  font-size: 0.7rem;
  color: #191919;
  margin-top: 0.4rem;
}
